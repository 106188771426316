const gql = String.raw;

// ==============================================

const price_range_connection = gql`
  priceRange {
    minVariantPrice {
      amount
      currencyCode
    }
  }
`;

// ==============================================

const variants_connection = gql`
  variants(first:4) {
    edges {
      node {
        id
        title
        weight
        weightUnit
        availableForSale
        quantityAvailable
        priceV2 {
          amount
        }
        image {
          altText
          height
          width
          url
        }
      }
    }
  }
`;

// ==============================================

const images_connection = gql`
  images(first: 1) {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        transformedSrc
        altText
        width
        height
      }
    }
  }
`;

// ==============================================

const productConnection = gql`
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          handle
          productType
          tags
          description
          vendor
          ${price_range_connection}
          ${images_connection}
          ${variants_connection}
        }
      }
`;

// ==============================================

const filter_query = gql`
  query F($q: String) {
    products(first: 20, query: $q) {
      ${productConnection}
    }
  }
`;

// ==============================================

export default filter_query;
