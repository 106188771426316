import { Drawer } from '@mantine/core';

// -components:
import Cart from '../../cart/Cart';

// -custom hooks:
// import { useMounted } from '../../../hooks/use-mounted';

// ==============================================

export default function F({ opened, setOpened, cart, setCart }) {
  // --------------------------------------------

  // const openDrawer = () => setOpened(true);
  const closeDrawer = () => setOpened(false);

  // --------------------------------------------

  return (
    <Drawer
      opened={opened}
      onClose={closeDrawer}
      title=''
      padding='xl'
      size='xl'
      position='right'
    >
      <Cart cart={cart} setCart={setCart} />
    </Drawer>
  );

  // --------------------------------------------
}

// ==============================================
