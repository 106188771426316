const gql = String.raw;

// ==============================================

const load_cart_query = gql`
  query GetCart($cart_id: ID!) {
    cart(id: $cart_id) {
      checkoutUrl
      estimatedCost {
        totalAmount {
          amount
        }
      }
      lines(first: 100) {
        edges {
          node {
            quantity
            estimatedCost {
              subtotalAmount {
                amount
                currencyCode
              }
              totalAmount {
                amount
                currencyCode
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
                product {
                  title
                }
                priceV2 {
                  amount
                  currencyCode
                }
                image {
                  id
                  height
                  width
                  url
                  altText
                }
              }
            }
          }
        }
      }
    }
  }
`;

// ==============================================

export default load_cart_query;
