import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import {
  Button,
  BackgroundImage,
  Center,
  Title,
  Text,
  Box,
  Overlay,
  MediaQuery,
} from '@mantine/core';

// ==============================================

// Image:
import img1 from './AdobeStock_185188995_Preview.jpeg';
import img2 from './AdobeStock_196226210_Preview.jpeg';
import img2_ from './AdobeStock_196226210_Preview-content-aware-fill.png';
import img3 from './AdobeStock_270898628_Preview.jpeg';
import img4 from './AdobeStock_463617997_Preview.jpeg';

// ==============================================

// GSAP:
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// ==============================================

const button_variants = {
  visible: {
    scale: 1.1,
    transition: {
      delay: 6,
      yoyo: 4,
      duration: 0.3,
    },
  },
  hover: {
    scale: 1.1,
    textShadow: '0px 0px 8px rgb(255, 255, 255)',
    boxShadow: '0px 0px 8px rgb(255, 255, 255)',
  },
};

// ==============================================

export default function Hero(p) {
  // --------------------------------------------

  const history = useHistory();

  // --------------------------------------------

  const handler = () => {
    history.push('/place-order');
  };

  // --------------------------------------------

  return (
    <Box sx={{ width: '100%', height: '100%' }} mx='auto'>
      <BackgroundImage
        // src='https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80'
        // src={img2}
        src={img2_}
        sx={{ width: '100%', height: '100%' }}
      >
        <Center p='md' style={{ width: '100%', height: '100%' }}>
          <div
            style={{
              // background: 'blue',
              height: '60%',
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Overlay opacity={0.5} color='#000' zIndex={0} />

            <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
              <Title order={1} sx={{ zIndex: 1, marginBottom: -25 }}>
                Dispensary Tulsa
              </Title>
            </MediaQuery>
            <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
              <Title order={5} sx={{ zIndex: 1, marginBottom: -45 }}>
                Dispensary Tulsa
              </Title>
            </MediaQuery>

            <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
              <Text size='xl' sx={{ zIndex: 1 }}>
                Tulsa's Premier Dispensary
              </Text>
            </MediaQuery>
            <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
              <Text size='md' sx={{ zIndex: 1 }}>
                Tulsa's Premier Dispensary
              </Text>
            </MediaQuery>

            <motion.div
              variants={button_variants}
              animate='visible'
              // whileHover='hover'
              style={{ zIndex: 1 }}
            >
              <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
                <Button
                  onClick={handler}
                  sx={{ color: 'black' }}
                  style={{ color: 'black' }}
                  color='red'
                  variant='filled'
                  size='md'
                >
                  {/* <Text size='sm'>Place Order</Text> */}
                  Place Order
                </Button>
              </MediaQuery>
              <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
                <Button
                  onClick={handler}
                  color='red'
                  variant='filled'
                  size='sm'
                >
                  {/* <Text size='sm'>Place Order</Text> */}
                  Place Order
                </Button>
              </MediaQuery>
            </motion.div>
          </div>
        </Center>
      </BackgroundImage>
    </Box>
  );
}
