import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SimpleGrid,
  Container,
  Group,
  Button,
  LoadingOverlay,
  MantineProvider,
  Image,
  // BackgroundImage,
  Center,
  Overlay,
  Text,
  Title,
} from '@mantine/core';

import BackgroundImage from './background-image/Backgound-Image';

import img1 from '../hero/AdobeStock_185188995_Preview.jpeg';
import img2 from '../hero/AdobeStock_196226210_Preview.jpeg';
import img2_ from '../hero/AdobeStock_196226210_Preview-content-aware-fill.png';
import img3 from '../hero/AdobeStock_270898628_Preview.jpeg';
import img4 from '../hero/AdobeStock_463617997_Preview.jpeg';

// ==============================================

export default function F() {
  // --------------------------------------------

  // const [loading, setLoading] = useState(false);
  const history = useHistory();

  // --------------------------------------------

  return (
    <>
      <MantineProvider
        theme={{
          colorScheme: 'dark',
          loader: 'bars',
          breakpoints: {
            xs: 500,
            sm: 800,
            md: 1000,
            lg: 1200,
            xl: 1400,
          },
        }}
        styles={{
          Button: (theme) => ({
            root: {
              borderRadius: '0rem',
            },
          }),
        }}
        defaultProps={{
          Container: {
            sizes: {
              xs: 540,
              sm: 720,
              md: 960,
              lg: 1140,
              xl: 1600,
            },
          },
        }}
      >
        <SimpleGrid
          cols={1}
          spacing='0px'
          breakpoints={[
            { minWidth: 'lg', cols: 4, spacing: '0px' },
            { minWidth: 'sm', cols: 2, spacing: '0px' },
            { minWidth: 'xs', cols: 1, spacing: '0px' },
          ]}
          style={{ position: 'relative', width: '100vw', minHeight: '300px' }}
        >
          <BackgroundImage img={img1} title='Flower' />
          <BackgroundImage img={img2} title='Edibles' />
          <BackgroundImage img={img3} title='Extract' />
          <BackgroundImage
            img={img4}
            title='CBD &#47; Delta-8 THC'
            link='/place-order'
          />
        </SimpleGrid>
      </MantineProvider>
    </>
  );

  // --------------------------------------------
}
