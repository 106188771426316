import { useState, useEffect } from 'react';
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  useMantineTheme,
  Select,
} from '@mantine/core';

import ProductDetailsModal from '../modals/product-details-modal/Product-Details-Modal';
import VariantDropdown from '../dropdowns/Variant-Dropdown';
import AddToCart from '../cart/Add-To-Cart';

// ==============================================

export default function F({ product, setCart, setCartDrawerOpen }) {
  // const { image, title, alt, slug, description, type, variants } = product;

  // console.log('Cart.js -- product: ', product);

  // --------------------------------------------

  const [modal_open, setModalOpen] = useState(false);

  // --------------------------------------------

  const {
    id,
    title,
    tags,
    productType: type,
    handle: slug,
    description,
    vendor,
    price,
    image,
    variants,
  } = product;

  // --------------------------------------------

  const { amount, currencyCode } = price;
  const { src, alt, width, height } = image;

  // --------------------------------------------

  const [selected, setSelected] = useState(variants?.[0].title);
  let variant = variants?.[0];

  useEffect(() => {
    // console.log('selected: ', selected);

    variant = variants.find((variant) => variant.title === selected);
    // console.log('variant: ', variant);
  }, [selected]);

  // --------------------------------------------

  const theme = useMantineTheme();
  const secondaryColor =
    theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7];

  // --------------------------------------------

  return (
    <>
      <ProductDetailsModal
        product={product}
        opened={modal_open}
        setOpened={setModalOpen}
      />

      <div style={{ width: 340, margin: 'auto' }}>
        <Card shadow='sm' padding='lg'>
          <Card.Section>
            <div style={{ position: 'relative' }}>
              <Image src={src} height={160} alt={alt} />
              <Badge
                color='pink'
                variant='light'
                style={{
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  width: 'fit-content',
                }}
              >
                {tags?.[0]}
              </Badge>
            </div>
          </Card.Section>

          <Group
            position='apart'
            style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
          >
            <Text weight={500}>{title}</Text>

            <Button
              variant='light'
              color='blue'
              onClick={() => setModalOpen(true)}
            >
              Details
            </Button>
          </Group>

          <Text
            size='sm'
            style={{ color: secondaryColor, lineHeight: 1.5 }}
            lineClamp={3}
          >
            {description}
          </Text>

          <VariantDropdown variants={variants} />

          <AddToCart
            variant_id={variant.id}
            setCart={setCart}
            setCartDrawerOpen={setCartDrawerOpen}
          />
        </Card>
      </div>
    </>
  );

  // --------------------------------------------
}
