import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import {
  Group,
  Checkbox,
  Button,
  RadioGroup,
  Radio,
  RangeSlider,
} from '@mantine/core';

import { container_variants } from '../../utils/variants';

import Cards from '../../comps/cards/Cards';
import { ChipsSingle, ChipsMultiple } from '../../comps/chips/Chips';

import request from '../../utils/shopify-req';
import filter_query from '../../utils/shopify/queries/filter-query';

import css from './Place-Order.module.scss';

// ==============================================

export default function PlaceOrderPage({ setCart, setCartDrawerOpen }) {
  // --------------------------------------------

  const history = useHistory();

  // --------------------------------------------

  const [products, setProducts] = useState([]);

  // --------------------------------------------

  // TODO: Set the initial state to the value from the previous 'page'
  const [checks, setChecks] = useState([false, false, false]);
  const [radio, setRadio] = useState('flower');

  // --------------------------------------------

  const [loading, setLoading] = useState(false);

  // --------------------------------------------

  useEffect(() => {
    // console.log('type: ', radio, '\ntags: ', checks);
    (async () => {
      setLoading(true);
      await getProducstByTagAndType();
      setLoading(false);
    })();
  }, [checks, radio]);

  // --------------------------------------------

  const getProducstByTagAndType = async () => {
    const tags_query = `${checks[0] ? 'tag:hybrid OR ' : ''}${
      checks[1] ? 'tag:indica OR ' : ''
    }${checks[2] ? 'tag:sativa OR ' : ''}`;

    const data = await request(filter_query, {
      q: `product_type:${radio} AND ${tags_query}`,
    });

    const normalize = (data) => {
      // console.log('data: ', data);
      // console.log('data?.products: ', data?.products);
      // console.log('data?.products?.edges: ', data?.products?.edges);

      const edges = data?.products?.edges;
      const nodes = edges.map((edge) => edge.node);

      return nodes.map((node) => {
        const {
          id,
          title,
          handle,
          productType,
          tags,
          description,
          vendor,
          priceRange,
          images,
          variants: vs,
        } = node;

        const { amount, currencyCode } = priceRange?.minVariantPrice;

        const { transformedSrc, altText, width, height } =
          images?.edges?.[0]?.node;

        const variants = vs.edges.map((edge) => {
          const { node } = edge;
          const {
            availableForSale,
            id,
            quantityAvailable,
            title,
            weight,
            weightUnit,
            priceV2,
            image,
          } = node;

          const { amount: price } = priceV2;
          const { url: image_url, alt: image_alt } = image;

          return {
            id,
            title,
            price,
            availableForSale,
            quantityAvailable,
            weight,
            weightUnit,
            image_url,
            image_alt,
          };
        });

        return {
          id,
          title,
          handle,
          productType,
          tags,
          description,
          vendor,
          price: { amount, currencyCode },
          image: { src: transformedSrc, alt: altText, width, height },
          variants,
        };
      });
    };

    setProducts(normalize(data));
  };

  // --------------------------------------------

  return (
    <motion.main
      className={css.main}
      variants={container_variants}
      initial='hidden'
      animate='visible'
      exit='exit'
    >
      <div className={css.container}>
        <div
          style={{
            // background: 'green',
            marginBottom: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '100px',
          }}
        >
          <div style={{ width: '400px' }}>
            <Group>
              <ChipsSingle radio={radio} setRadio={setRadio} />
            </Group>
          </div>
          <div style={{ width: '400px' }}>
            <ChipsMultiple setChecks={setChecks} />
          </div>
        </div>

        <Cards
          products={products}
          setCart={setCart}
          loading={loading}
          setCartDrawerOpen={setCartDrawerOpen}
        />
      </div>
    </motion.main>
  );
}
