import { useEffect, useState } from 'react';
import { Chips, Chip } from '@mantine/core';

// ==============================================

function Single({ radio, setRadio }) {
  // string value when multiple is false (default)
  // const [value, setValue] = useState('flower');

  return (
    <Chips
      multiple={false}
      value={radio}
      onChange={setRadio}
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        // background: 'hotpink',
        width: '100%',
        maxWidth: '100vw',
        padding: '0 0.3rem',
      }}
    >
      <Chip value='flower'>Flower</Chip>
      <Chip value='extract'>Extract</Chip>
      <Chip value='edibles'>Edibles</Chip>
    </Chips>
  );
}

// ==============================================

function Multiple({ setChecks }) {
  // array of strings value when multiple is true
  const [values, setValues] = useState(['hybrid']);
  const [outs, setOuts] = useState([false, false, false]);

  useEffect(() => {
    // ['hybrid']  ->  [true,  false, false]
    // ['indica']  ->  [false, true,  false]
    // ['sativa']  ->  [false, false, true ]
    const out = ['false', 'false', 'false'];

    if (values.find((value) => value === 'hybrid')) out[0] = true;
    else out[0] = false;

    if (values.find((value) => value === 'indica')) out[1] = true;
    else out[1] = false;

    if (values.find((value) => value === 'sativa')) out[2] = true;
    else out[2] = false;

    // -Don't allow all to be unchecked:
    if (out.find((o) => o === true)) {
      setChecks(out);
    }

    // console.log('values: ', values, '  ->  out:', out);
    setOuts(out);
  }, [values]);

  useEffect(() => {
    // -If all checks un-selected then select all (cannot have all un-selected)
    if (outs[0] === false && outs[1] === false && outs[2] === false) {
      setValues(['hybrid', 'indica', 'sativa']);
    }
  }, [outs]);

  return (
    <Chips
      value={values}
      onChange={setValues}
      multiple
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        // background: 'hotpink',
        width: '100%',
        maxWidth: '100vw',
        padding: '0 0.5rem',
      }}
    >
      <Chip value='hybrid'>Hybrid</Chip>
      <Chip value='indica'>Indica</Chip>
      <Chip value='sativa'>Sativa</Chip>
    </Chips>
  );
}

// ==============================================

export { Single as ChipsSingle, Multiple as ChipsMultiple };
