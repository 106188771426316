import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Drawer, Text } from '@mantine/core';
import { gsap } from 'gsap';

// hooks:
import { useMounted } from '../../../hooks/use-mounted';

import css from './Nav-Drawer.module.scss';

export default function F({ opened, setOpened }) {
  // ------------------------------------------------------

  const links_ref = useRef([]); // links.current[n]
  const tl_links_ref = useRef(null);

  const [count, setCount] = useState(0);
  const [no_click_outside, setNoClickOutside] = useState(true);

  // ------------------------------------------------------

  const mounted = useMounted();

  // ------------------------------------------------------

  useEffect(() => {
    setTimeout(() => {
      if (mounted && opened === true) {
        handler();
        // disable click outside drawer to close until animation is complete to avoid reversing before animation completion
        setTimeout(() => setNoClickOutside(false), 1e3);
      }
    }, 200);
  }, [opened]);

  // ------------------------------------------------------

  const links = [
    { title: 'Home', route: '/' },
    { title: 'Place Order', route: '/place-order' },
    { title: 'About', route: '/about' },
    { title: 'Map', route: '/map' },
  ];

  // ------------------------------------------------------

  const handler = () => {
    if (count === 0) {
      tl_links_ref.current = gsap.timeline().to(links_ref.current, {
        y: '-50px', // gsap transition === height of $link_h
        ease: 'Back.easeOut',
        stagger: 0.1,
      });
    } else {
      tl_links_ref.current.reverse();
    }
    setCount((prev) => (prev + 1) % 2);
  };

  // ------------------------------------------------------

  const openDrawer = () => {};

  // ------------------------------------------------------

  const closeDrawer = () => {
    setNoClickOutside(true);
    handler();
    setTimeout(() => {
      setOpened(false);
    }, 1e3);
  };

  // ------------------------------------------------------

  return (
    <Drawer
      opened={opened}
      onClose={closeDrawer}
      title=''
      padding='xl'
      size='xl'
      // transition={scaleY}
      closeOnClickOutside={!no_click_outside}
      withCloseButton={!no_click_outside}
    >
      <div className={css.nav_drawer_container}>
        {links.map((link, idx) => {
          return (
            <div key={link.title} className={css.link_container}>
              <Text
                className={css.link}
                ref={(el) => (links_ref.current[idx] = el)}
                component={Link}
                variant='link'
                to={link.route}
                onClick={closeDrawer}
              >
                {link.title}
              </Text>
            </div>
          );
        })}
      </div>
    </Drawer>
  );
}
