import { useState } from 'react';
import {
  SimpleGrid,
  Container,
  Group,
  Button,
  LoadingOverlay,
  MantineProvider,
} from '@mantine/core';

import Card from './Card';

// ==============================================

export default function F({ products, setCart, loading, setCartDrawerOpen }) {
  // --------------------------------------------

  // const [loading, setLoading] = useState(false);

  // --------------------------------------------

  return (
    <main>
      <MantineProvider
        theme={{
          colorScheme: 'dark',
          loader: 'bars',
          breakpoints: {
            // xs: 500,
            // sm: 800,
            // md: 1020,
            // lg: 1200,
            // xl: 1400,
          },
        }}
        styles={{
          Button: (theme) => ({
            root: {
              borderRadius: '0rem',
            },
          }),
        }}
      >
        <SimpleGrid
          cols={1}
          spacing='lg'
          breakpoints={[
            { minWidth: 'xl', cols: 4 /*spacing: 'sm'*/ },
            { minWidth: 'md', cols: 3 /*spacing: 'sm'*/ },
            { minWidth: 'sm', cols: 2 /*spacing: 'sm'*/ },
            { minWidth: 'xs', cols: 1 /*spacing: 'sm'*/ },
          ]}
          style={{ position: 'relative' }}
        >
          <LoadingOverlay visible={loading} />
          {products.map((product, idx) => {
            return (
              <Card
                key={`${product.slug}-${idx}`}
                product={product}
                setCart={setCart}
                setCartDrawerOpen={setCartDrawerOpen}
              />
            );
          })}
        </SimpleGrid>
      </MantineProvider>
    </main>
  );

  // --------------------------------------------
}
