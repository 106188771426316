import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Popover,
  Button,
  Title,
  Text,
  Image,
  ScrollArea,
  Center,
} from '@mantine/core';

// import createCart from '../../utils/shopify/requests/create-cart';
// import loadCart from '../../utils/shopify/requests/load-cart';

import NumberInput from '../number-input/Number-Input';

import { QuestionFull, QuestionEmpty } from '../svg/Question';
import { TrashEmpty } from '../svg/Trash';

import getCart from '../../utils/shopify/get-cart';

import {
  // setCartLS,
  // getCartLS,
  removeCartLS,
  setCartLSStatus,
  getCartLSStatus,
} from '../../utils/shopify/cart-local-storage';

import CartCtx from '../../context/cart-context';

import css from './Cart.module.scss';

// ==============================================

const LineItem = ({ line, idx }) => {
  // --------------------------------------------

  const [poppover_1, setPopover_1] = useState(false);

  const [quantity, setQuantity] = useState(false);

  // --------------------------------------------

  const {
    id,
    product_title,
    variant_title,
    variant_price,
    line_item_quantity,
    line_item_sub_total,
    image_height,
    image_width,
    image_url,
    image_alt,
  } = line;

  // --------------------------------------------

  useEffect(() => {
    // NOTE: Not positive I am handling the quantity correct in this or the NumberInput child component!
    setQuantity(line_item_quantity);
  }, [line_item_quantity]);

  // --------------------------------------------

  return (
    <li className={css.line_item}>
      <div className={css.left}>
        <Image src={image_url} alt={image_url} height='100' width='100' />
      </div>
      <div className={css.right}>
        <div className={css.container}>
          <Title order={6} className={css.title}>
            <Text size='lg'>{product_title}</Text>
            <div className={css.x}>
              <TrashEmpty />
            </div>
          </Title>
          <Text size='md'>{variant_title}</Text>
          <Text size='ms'>${Number(variant_price).toFixed(2)}</Text>
          <NumberInput quantity={quantity} setQuantity={setQuantity} />

          <Popover
            opened={poppover_1}
            onClose={() => setPopover_1(false)}
            sx={{ width: '100%' }}
            target={
              <Text
                size='sm'
                onClick={() => setPopover_1((prev) => !prev)}
                sx={{
                  // background: 'red',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                Sub-Total: ${line_item_sub_total}{' '}
                {poppover_1 ? <QuestionEmpty /> : <QuestionFull />}
              </Text>
            }
            width={260}
            position='bottom'
            withArrow
          >
            <div style={{ display: 'flex' }}>
              <Text size='sm'>= quantity &times; price</Text>
            </div>
          </Popover>
        </div>
      </div>
    </li>
  );
};

// ==============================================

export default function Cart(p) {
  // --------------------------------------------

  // const location = useLocation();

  // --------------------------------------------

  const cartCtx = useContext(CartCtx);
  // console.log('cartCtx: ', cartCtx);

  // --------------------------------------------

  const emptyCart = () => {
    const INIT_CART = {
      cart_id: null,
      checkout_url: null,
      estimated_cost: null,
      lines: [],
    };
    p.setCart(INIT_CART);
    removeCartLS();
  };

  // --------------------------------------------

  const resetCart = async () => {
    emptyCart();
    await getCart(p.setCart);
  };

  // --------------------------------------------

  // --------------------------------------------

  return (
    <main className={css.cart}>
      {/* <ScrollArea sx={{ width: '100%' }}> */}
      <ul>
        {p.cart?.lines?.length > 0 &&
          p.cart.lines.map((line, idx) => (
            <LineItem key={line?.id} line={line} />
          ))}
      </ul>

      <div className={css.price_summary}>
        <Text className={css.A1}>Sub-Total:</Text>
        <Text className={css.A2}>${p.cart?.cart_sub_total}</Text>
        <Text className={css.B1}>Tax:</Text>
        <Text className={css.B2}>TODO</Text>
        <Text className={css.C1}>Total:</Text>
        <Text className={css.C2}>${p.cart?.cart_estimated_total}</Text>

        {/* <button onClick={emptyCart}>Empty (remove) Cart</button> */}

        <div className={css.D}>
          <Button variant='outline' onClick={resetCart}>
            Empty Cart
          </Button>
          <Button
            onClick={() => {
              window.location.href = p.cart?.checkout_url;
            }}
          >
            Checkout
          </Button>
        </div>
      </div>
      {/* </ScrollArea> */}
    </main>
  );

  // --------------------------------------------
}
