import { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Group,
  Paper,
  Image,
  Badge,
  Title,
  Text,
  MantineProvider,
  Select,
} from '@mantine/core';

import css from './Product-Details-Modal.module.scss';
import layout from './layout.module.scss';
import VariantDropdown from './Variant-Dropdown';
import VariantChipsSelect from './Variant-Chips-Select';
import NumberInput from '../../number-input/Number-Input';

import { StarFull, StarHalfFull, StarEmpty } from '../../svg/Stars';
import { HeartEmpty, HeartFull } from '../../svg/Hearts';
import { Share } from '../../svg/Share';

const border = 'dashed green 5px';

// ==============================================

export default function F({ product, opened, setOpened }) {
  // --------------------------------------------

  const {
    id,
    title,
    tags,
    productType: type,
    handle: slug,
    description,
    vendor,
    price,
    image,
    variants,
  } = product;

  const { amount, currencyCode } = price;
  const { src, alt, width, height } = image;

  // --------------------------------------------

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title='Product Details'
        closeOnClickOutside={false}
        withCloseButton={true}
        classNames={{
          root: 'your-root-class',
          inner: 'your-inner-class',
          modal: 'your-modal-class',
          header: 'your-header-class',
          overlay: 'your-overlay-class',
          title: 'your-title-class',
          body: 'your-body-class',
          close: 'your-close-class',
        }}
        styles={{
          // root: { border },
          // inner: { border },
          modal: {
            width: '90%',
            minHeight: '1000px',
            display: 'grid',
            gridTemplateRows: '50px 1fr',
          },
          header: { border, margin: 0 },
          // overlay: { border },
          // title: { border },
          body: { border },
          // close: { border },
        }}
      >
        <div className={layout.outer_container}>
          <div className={layout.inner_container}>
            <section className={layout.A}>
              <div className={css.image_container}>
                <Image radius='md' src={src} alt={alt} />
                <Badge className={css.badge} color='blue' variant='light'>
                  {tags?.[0]}
                </Badge>
              </div>
            </section>
            <section className={layout.B}>
              <div className={layout.B1}>
                <Paper>
                  <Title order={3}>{title}</Title>
                  <Text size='xl'>${Number(amount).toFixed(2)}</Text>
                  <Group>
                    <StarFull size={16} />
                    <StarFull size={16} />
                    <StarFull size={16} />
                    <StarHalfFull size={16} />
                    <StarEmpty size={16} />
                    <Text underline>(175)</Text>
                  </Group>
                  <Text size='sm' style={{ lineHeight: 1.5 }} lineClamp={2}>
                    {description}
                  </Text>
                  <Text underline>Read more</Text>
                </Paper>
              </div>
              <div className={layout.B2}>
                <Paper>
                  {/* <VariantDropdown variants={variants} /> */}
                  <VariantChipsSelect variants={variants} />

                  <Text>Quantity:</Text>
                  <NumberInput />

                  <Button>ADD TO CART</Button>

                  <Group>
                    <Group>
                      <HeartEmpty />
                      <Text>Save</Text>
                    </Group>
                    <Group>
                      <Share />
                      <Text>Share</Text>
                    </Group>
                  </Group>
                </Paper>
              </div>
            </section>
            <section className={layout.C}>
              <div className={layout.C1}>
                <Paper>
                  <Text size='xl'>Description:</Text>
                  <Text size='sm' style={{ lineHeight: 1.5 }}>
                    {description}
                  </Text>
                </Paper>
              </div>
              <div className={layout.C2}>
                <Text size='xl'>Details:</Text>
                <Text>THC:</Text>
                <Text>CBD:</Text>
                <Text>Strain:</Text>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
}
