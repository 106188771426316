const gql = String.raw;

// ==============================================

const create_cart_mutation = gql`
  mutation CreateCart {
    cartCreate {
      cart {
        checkoutUrl
        id
      }
    }
  }
`;

// ==============================================

export default create_cart_mutation;
