import { motion } from 'framer-motion';
import { Center, Text } from '@mantine/core';

import { container_variants } from '../../utils/variants';

// ==============================================

export default function AboutPage() {
  return (
    <motion.main
      // className={css.main}
      variants={container_variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      style={{ height: '100vh' }}
    >
      <Center sx={{ height: '100%' }}>
        <Text size='xl'>Coming soon to a web-browser near you!!!</Text>
      </Center>
    </motion.main>
  );
}
