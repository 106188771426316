import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Group } from '@mantine/core';

// ==============================================

export default function F({ opened, setOpened }) {
  // --------------------------------------------

  const history = useHistory();

  // --------------------------------------------

  useEffect(() => {
    setTimeout(() => setOpened(true), 2e3);
  }, []);

  // --------------------------------------------

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title='Are you 21+ years old?'
        closeOnClickOutside={false}
        withCloseButton={false}
        centered
      >
        <Group position='center'>
          <Button onClick={() => setOpened(false)}>Yes</Button>
          <Button onClick={() => history.push('/go-away')}>No</Button>
        </Group>
      </Modal>
    </>
  );
}
