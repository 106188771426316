import { useEffect, useState, useContext } from 'react';
import { Button } from '@mantine/core';

import {
  getCartLS,
  setCartLSStatus,
} from '../../utils/shopify/cart-local-storage';
import addToCart from '../../utils/shopify/requests/add-to-cart';

import normalizeCart from '../../utils/shopify/requests/normalize-cart';

// ==============================================

export default function AddToCart({ variant_id, setCart, setCartDrawerOpen }) {
  // --------------------------------------------

  // --------------------------------------------

  const handler = async () => {
    setCartDrawerOpen(true);

    const local_cart_data = getCartLS();
    const { cart_id } = local_cart_data;

    const updated_cart = await addToCart({ cart_id, variant_id });

    console.log('getCart() -- updated_cart: ', updated_cart);

    const normalized_updated_cart = normalizeCart(updated_cart?.cartLinesAdd);

    const { cart_sub_total, cart_estimated_total, lines } =
      normalized_updated_cart;

    setCart({
      // -From local-storage cart:
      cart_id: local_cart_data.cart_id,
      checkout_url: local_cart_data.checkout_url,
      // -From updated cart with cart_id on Shopify server:
      cart_sub_total,
      cart_estimated_total, // TODO: Add tax!
      lines,
    });

    // setCartLSStatus('dirty');
  };

  // --------------------------------------------

  return (
    <Button
      variant='light'
      color='blue'
      fullWidth
      // style={{ marginTop: 14 }}
      onClick={handler}
    >
      Add to Cart
    </Button>
  );
}
