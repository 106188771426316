import createCart from './requests/create-cart';
import loadCart from './requests/load-cart';

import { getCartLS, setCartLS } from './cart-local-storage';

import normalizeCart from './requests/normalize-cart';

// ==============================================

const getCart = async (setCart) => {
  let local_cart_data = getCartLS();
  if (!local_cart_data) {
    local_cart_data = await createCart();
    setCartLS(local_cart_data);
  }
  const existing_cart = await loadCart(local_cart_data.cart_id);

  console.log('getCart() -- existing_cart: ', existing_cart);

  const normalized_existing_cart = normalizeCart(existing_cart);

  setCart({
    // -From local-storage cart:
    cart_id: local_cart_data.cart_id,
    checkout_url: local_cart_data.checkout_url,
    // -From existing cart with cart_id on Shopify server:
    cart_sub_total: normalized_existing_cart.cart_sub_total,
    cart_estimated_total: normalized_existing_cart.cart_estimated_total, // TODO: Add tax!
    lines: normalized_existing_cart.lines,
  });
};

// ==============================================

export default getCart;
