const setCartLS = (x) =>
  window.localStorage.setItem('LS:cart', JSON.stringify(x)); //                -sets JSON.string()'ified value into local-storage
const getCartLS = () => JSON.parse(window.localStorage.getItem('LS:cart')); // -gets JSON.parse()'d value from local-storage
const removeCartLS = () => window.localStorage.removeItem('LS:cart'); //       -removes LS:cart from local-storage

// ==============================================

const setCartLSStatus = (status) =>
  window.localStorage.setItem('LS:cart:status', status);
const getCartLSStatus = () => window.localStorage.getItem('LS:cart:status');

// ==============================================

export { setCartLS, getCartLS, removeCartLS, setCartLSStatus, getCartLSStatus };
