// ==============================================

// <motion.main>:
const container_variants = {
  hidden: {
    // Can call 'hidden' anything (e.g., initial)
    x: '100vw',
    opacity: 0,
  }, // initial state
  visible: {
    // Can call 'visible' anything (e.g., animate)
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      mass: '0.4', // higher mass moves slower
      damping: '8', // strength of opposing force
      // delay: 0.1,
      when: 'beforeChildren' /* complete this animation before any children animations occur */,
      staggerChildren: 0.4,
    }, // only animating-in, so we only apply transition to visible property
  }, // final state
  exit: {
    x: '-100vw',
    transition: { ease: 'easeInOut' },
  },
};

// ==============================================

// <motion.div>:
const buttons_variants = {
  hidden: {
    x: '-100vw',
  },
  visible: {
    x: 0,
    transition: { type: 'spring', stiffness: 120 },
  },
};

// ==============================================

export { container_variants, buttons_variants };
