import { useState, useEffect } from 'react';
import { Select, Chips, Chip } from '@mantine/core';

// ==============================================

export default function F({ variants }) {
  const [selected, setSelected] = useState(variants?.[0].title);
  let variant = variants?.[0];

  useEffect(() => {
    // console.log('selected: ', selected);

    variant = variants.find((variant) => variant.title === selected);
    // console.log('variant: ', variant);
  }, [selected]);

  return (
    <Select
      label='Choose amount:'
      placeholder='Pick one'
      data={variants.map((variant) => variant.title)}
      transition='pop-top-left'
      transitionDuration={80}
      transitionTimingFunction='ease'
      value={selected}
      onChange={setSelected}
    />
  );
}
