import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SimpleGrid,
  Container,
  Group,
  Button,
  LoadingOverlay,
  MantineProvider,
  Image,
  BackgroundImage,
  Center,
  Overlay,
  Text,
  Title,
} from '@mantine/core';

// ==============================================

export default function F({ title, img }) {
  // --------------------------------------------

  // const [loading, setLoading] = useState(false);
  const history = useHistory();

  // --------------------------------------------
  return (
    <BackgroundImage
      // src='https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80'
      src={img}
      sx={{ width: '100%', height: '300px', zIndex: 0, position: 'relative' }}
    >
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      <Center p='md' style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            height: '50%',
            display: 'grid',
            placeItems: 'center',
            // border: 'solid red 3px',
          }}
        >
          <Overlay opacity={0.4} color='#000' zIndex={-1} />

          <Title order={1} sx={{ zIndex: 1, color: 'white' }}>
            {title}
          </Title>

          {/* <Text size='xl' sx={{ zIndex: 1 }}>
            Tulsa's Premier Dispensary
          </Text> */}
          <Button
            onClick={() => history.push('/place-order')}
            // sx={{ background: '#FF5757' }}
            color='green'
          >
            Place Order
          </Button>
        </div>
      </Center>
    </BackgroundImage>
  );
}
