import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SimpleGrid,
  Container,
  Group,
  Button,
  LoadingOverlay,
  MantineProvider,
  Image,
  BackgroundImage,
  Center,
  Overlay,
  Text,
  Title,
  Blockquote,
  Popover,
} from '@mantine/core';

import { StarFull, StarHalfFull, StarEmpty } from '../../../svg/Stars';

// ==============================================

export default function F({
  quote,
  author,
  full_stars,
  half_stars,
  empty_stars,
  img,
}) {
  // --------------------------------------------

  // const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [opened, setOpened] = useState(false);

  // --------------------------------------------
  return (
    <BackgroundImage
      // src='https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80'
      src={img}
      sx={{ width: '100%', height: '300px', zIndex: 0, position: 'relative' }}
    >
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      {/* TODO: Clean up the z-index here: WHY DO I NEED z-index: -1 on overlay??? */}
      <Center p='md' style={{ width: '100%', height: '100%', cursor: 'grab' }}>
        <div
          style={{
            height: '50%',
            width: '100%',
            display: 'grid',
            placeItems: 'center',
            // border: 'solid red 3px',
          }}
        >
          <Overlay opacity={0.6} color='#000' zIndex={-1} />
          {/* <Text size='xl' sx={{ zIndex: 1, color: 'white' }}>
            {quote}
          </Text> */}
          {/* <Text size='lg' sx={{ zIndex: 1, color: 'white' }}>
            {author}
          </Text> */}

          <Blockquote
            cite={`– ${author}`}
            sx={{ zIndex: 1, color: 'white', width: '90%' }}
          >
            <Popover
              opened={opened}
              onClose={() => setOpened(false)}
              target={
                <Text
                  onClick={() => setOpened((o) => !o)}
                  lineClamp={3}
                  sx={{ cursor: 'pointer' }}
                >
                  {quote}
                </Text>
              }
              width={'80vw'}
              position='bottom'
              withArrow
            >
              <Text>&ldquo;{quote}&rdquo;</Text>
            </Popover>

            <Center sx={{ marginTop: '1rem' }}>
              <Group>
                {[...new Array(full_stars)].map((_, idx) => (
                  <StarFull key={`${_}-${idx}`} size={16} />
                ))}

                {[...new Array(half_stars)].map((_, idx) => (
                  <StarHalfFull key={`${_}-${idx}`} size={16} />
                ))}

                {new Array(empty_stars).map((_, idx) => (
                  <StarEmpty key={`${_}-${idx}`} size={16} />
                ))}
              </Group>
            </Center>
          </Blockquote>
        </div>
      </Center>
    </BackgroundImage>
  );
}
