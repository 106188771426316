import { useState, useMemo, useCallback, useRef } from 'react';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  // DirectionsRenderer,
  // Circle,
  // MarkerClusterer,
} from '@react-google-maps/api';
// import Places from './places';
// import Distance from './distance';

// import svg from './AdobeStock_269274914_Preview.svg';

// ==============================================

// https://developers.google.com/maps/documentation/javascript/react-map
export default function Map() {
  // --------------------------------------------

  const map_ref = useRef();
  const [show_info, setShowInfo] = useState(false);

  const center = useMemo(() => ({ lat: 36.1, lng: -95.9 }), []);
  const options = useMemo(() => ({
    // mapId: 'fb8514f3e7c2f5d8', // light - retail
    mapId: '6ef30c9ccd11d9ef', // dark
    // disableDefaultUI: true,
    clickableIcons: false,
  }));

  // --------------------------------------------

  const onLoad = useCallback((map) => (map_ref.current = map), []);

  // --------------------------------------------

  return (
    <GoogleMap
      zoom={10}
      center={center}
      mapContainerClassName='map-container'
      options={options}
      onLoad={onLoad}
    >
      <Marker
        position={center}
        // icon={{ url: svg, scaledSize: new window.google.maps.Size(25, 25) }}
        icon={{
          url: 'https://upload.wikimedia.org/wikipedia/commons/a/a8/Cannabis_leaf.svg',
          scaledSize: new window.google.maps.Size(45, 45),
        }}
        onClick={() => setShowInfo(true)}
      />

      {/* https://youtu.be/Pf7g32CwX_s?t=991 */}
      {show_info && (
        <InfoWindow position={center} onCloseClick={() => setShowInfo(false)}>
          <div>Dispensary!</div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}
