const gql = String.raw;

// ==============================================

const add_to_cart_mutation = gql`
  mutation AddToCart($cart_id: ID!, $variant_id: ID!) {
    cartLinesAdd(
      cartId: $cart_id
      lines: [{ quantity: 1, merchandiseId: $variant_id }]
    ) {
      cart {
        checkoutUrl
        estimatedCost {
          totalAmount {
            amount
          }
        }
        lines(first: 100) {
          edges {
            node {
              quantity
              estimatedCost {
                subtotalAmount {
                  amount
                  currencyCode
                }
                totalAmount {
                  amount
                  currencyCode
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  product {
                    title
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  image {
                    id
                    height
                    width
                    url
                    altText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// ==============================================

export default add_to_cart_mutation;
