import { Paper, LoadingOverlay } from '@mantine/core';
import { useLoadScript } from '@react-google-maps/api';
import Map from './Map';

const GOOGLE_API_KEY = 'AIzaSyBPyGV6sOpSU3qhkDKYH2aihP-77Qmd-N0';

// ==============================================

export default function GoogleMap() {
  // --------------------------------------------

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    // libraries: ['places'],
  });

  // --------------------------------------------
  if (!isLoaded) {
    return (
      // <div style={{ height: '400px', width: '100%', position: 'relative' }}>
      //   <LoadingOverlay visible={true} />
      // </div>
      <div>LOADING</div>
    );
  }

  // --------------------------------------------

  return (
    <Paper
      className='map-container-container'
      shadow='sm'
      radius='sm'
      style={{
        padding: 0,
        overflow: 'hidden',
        width: '100%',
      }}
      // withBorder
    >
      <Map />
    </Paper>
  );
}
