import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Button,
  BackgroundImage,
  Center,
  Title,
  Text,
  Box,
  Overlay,
  LoadingOverlay,
  Paper,
  Image,
} from '@mantine/core';

// Components:
import AgeModal from '../../comps/modals/verify-age-modal/Verify-Age-Modal';

import Hero from '../../comps/home-page/hero/Hero';
import CategoryGrid from '../../comps/home-page/category-grid/Category-Grid';
import Carousel from '../../comps/home-page/carousel/Carousel';

// CSS:
import css from './Home.module.scss';

// ==============================================

const container_variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { delay: 0.6, duration: 1.5 },
  },
  exit: {
    x: '-100vw',
    transition: { ease: 'easeInOut' },
  },
};

// ==============================================

export default function Home() {
  // --------------------------------------------

  // const history = useHistory();

  const [age_model_open, setAgeModalOpen] = useState(false);

  // --------------------------------------------

  return (
    <motion.main className={css.main}>
      <AgeModal opened={age_model_open} setOpened={setAgeModalOpen} />

      <motion.div
        variants={container_variants}
        initial='hidden'
        animate='visible'
        exit='exit'
      >
        <section className={css.hero}>
          <Hero
            container_class={css.container}
            age_model_open={age_model_open}
          />
        </section>

        <section className={css.banner}>
          <Center p='md' style={{ width: '100%', height: '100%' }}>
            Banner
          </Center>
        </section>

        <section className={css.product_categories}>
          <CategoryGrid />
        </section>

        <section className={css.reviews}>
          {' '}
          <Center py='xl' style={{ height: '100%', cursor: 'grab' }}>
            <Paper sx={{ overflow: 'hidden' }}>
              <Carousel />
            </Paper>
          </Center>
        </section>

        <section className={css.footer}>
          <Center p='md' style={{ width: '100%', height: '100%' }}>
            Footer
          </Center>
        </section>
      </motion.div>
    </motion.main>
  );
}
