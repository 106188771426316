import { useState, createContext } from 'react';

// ==============================================

const INIT_CART = {
  cart_id: null,
  checkout_url: null,
  estimated_cost: null,
  lines: [],
};

// ==============================================

const CartCtx = createContext({ cart: INIT_CART, setCart: () => {} });

// ==============================================

const CartCtxProvider = ({ children }) => {
  // --------------------------------------------

  const [cart, setCart] = useState(INIT_CART);

  // --------------------------------------------

  const context = {
    cart,
    setCart,
  };

  // --------------------------------------------

  return <CartCtx.Provider value={context}>{children}</CartCtx.Provider>;

  // --------------------------------------------
};

// ==============================================

export { CartCtxProvider };
export default CartCtx;

// ==============================================
