import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Button,
  BackgroundImage,
  Center,
  Title,
  Text,
  Box,
  Overlay,
  LoadingOverlay,
  Paper,
} from '@mantine/core';

import MapContainer from '../../comps/map/Google-Map';

import { container_variants } from '../../utils/variants';

// ==============================================

export default function MapPage() {
  // --------------------------------------------

  const [lazy_map, setLazyMap] = useState(
    <div style={{ height: '400px', width: '100%', position: 'relative' }}>
      <LoadingOverlay visible={true} />
    </div>
  );

  useEffect(() => {
    setTimeout(() => {
      setLazyMap(<MapContainer />);
    }, 1e3);
  }, []);

  // --------------------------------------------
  return (
    <motion.main
      // className={css.main}
      variants={container_variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      style={{ height: '100vh' }}
    >
      <Center px='md' py='xl' style={{ height: '100%', position: 'relative' }}>
        {lazy_map}
      </Center>
    </motion.main>
  );
}
