// React / Router:
import { useState, useEffect, useRef, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// Libs:
import { MantineProvider, ScrollArea } from '@mantine/core';
import { AnimatePresence } from 'framer-motion';

// Pages:
import HomePage from './pages/home/Home';
// import Base from './pages/place-order/step-1/Place-Order-Step-1';
// import Strain from './pages/place-order/step-2/Place-Order-Step-2';
import PlaceOrderPage from './pages/place-order/Place-Order';
import GoAwayPage from './pages/go-away/Go-Away-Page';
import MapPage from './pages/map/Map-Page';
import AboutPage from './pages/about/About-Page';

// Components:
import Header from './comps/header/Header';
import NavDrawer from './comps/drawers/nav-drawer/Nav-Drawer';
import CartDrawer from './comps/drawers/cart-drawer/Cart-Drawer';

// Cart:
import getCart from './utils/shopify/get-cart';

// CSS:
import './App.scss';

// GSAP:
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

// ==============================================

// 3 steps to animate routes:
//  1. Surround App.js <Switch> with <AnimatePresence>
//  2. Tell AnimatePresence when the route location changes via useLocation hook.
//      --Pass location=useLocation() into <Switch>
//      --exit transition event is now fired when route changes
//  3. Add exit animations to each of the components

// ==============================================

export default function App() {
  // ------------------------------------------------------

  // -Hamburger / Drawer State:
  const [drawer_open, setDrawerOpen] = useState(false);
  const [cart_drawer_open, setCartDrawerOpen] = useState(false);

  // -Cart State:
  const [cart, setCart] = useState({
    cart_id: null,
    checkout_url: null,
    estimated_cost: null,
    lines: [],
  });

  // ------------------------------------------------------

  // -Initialize Cart
  //  --Either grab from local-storage
  //    or create new cart:
  useEffect(() => {
    getCart(setCart);
  }, []);

  // ------------------------------------------------------

  const location = useLocation();

  // ------------------------------------------------------

  const header_ref = useRef(null);
  const scroll_area_ref = useRef(null);

  // ------------------------------------------------------

  // Scroll-Animation:
  useEffect(() => {
    const header = header_ref.current;
    const scroll_area = scroll_area_ref.current;

    const span = header.querySelector('div');

    const tl = gsap.timeline({
      scrollTrigger: {
        scroller: scroll_area,
        trigger: header,
        start: 'bottom 100px',
        end: 'bottom top',
        scrub: 1,
        // markers: true,
      },
    });
    tl.to(header, {
      backgroundColor: 'rgba(20,22,24,1)',
      height: '85px',
    });
    tl.fromTo(
      span,
      { color: 'rgba(255, 255, 255, 0)' },
      { color: 'rgba(255, 255, 255, 1)' }
    );
  }, []);

  // ------------------------------------------------------

  return (
    <div id='page'>
      <MantineProvider
        theme={{
          colorScheme: 'dark',
          loader: 'bars',
          // breakpoints: {
          //   xs: 500,
          //   sm: 800,
          //   md: 1000,
          //   lg: 1200,
          //   xl: 1400,
          // },
          fontSizes: {
            xs: 16,
            sm: 18,
            md: 20,
            lg: 26,
            xl: 36,
          },
          headings: {
            // fontFamily: CSSProperties['fontFamily'],
            fontWeight: 400,
            sizes: {
              h1: { fontSize: 68 /*, lineheight: x */ },
              h2: { fontSize: 54 /*, lineheight: x */ },
              h3: { fontSize: 50 /*, lineheight: x */ },
              h4: { fontSize: 46 /*, lineheight: x */ },
              h5: { fontSize: 40 /*, lineheight: x */ },
              h6: { fontSize: 34 /*, lineheight: x */ },
            },
          },
        }}
      >
        <ScrollArea viewportRef={scroll_area_ref} style={{ height: '100%' }}>
          {/* Portal: Maps to sibling of #root */}
          <NavDrawer opened={drawer_open} setOpened={setDrawerOpen} />
          {/* Portal: Maps to sibling of #root */}
          <CartDrawer
            opened={cart_drawer_open}
            setOpened={setCartDrawerOpen}
            cart={cart}
            setCart={setCart}
          />
          <Header
            ref={header_ref}
            setDrawerOpen={setDrawerOpen}
            setCartDrawerOpen={setCartDrawerOpen}
          />
          <div id='page-content'>
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.key}>
                <Route path='/place-order' exact>
                  <PlaceOrderPage
                    setCart={setCart}
                    setCartDrawerOpen={setCartDrawerOpen}
                  />
                </Route>
                <Route path='/go-away' exact>
                  <GoAwayPage />
                </Route>

                <Route path='/map' exact>
                  <MapPage />
                </Route>

                <Route path='/about' exact>
                  <AboutPage />
                </Route>

                <Route path='/' exact>
                  <HomePage />
                </Route>
              </Switch>
            </AnimatePresence>
          </div>
        </ScrollArea>
      </MantineProvider>
    </div>
  );

  // ------------------------------------------------------
}

// ========================================================
