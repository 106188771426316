import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
  Button,
  // BackgroundImage,
  Center,
  Title,
  Text,
  Box,
  Overlay,
  LoadingOverlay,
  Paper,
  Image,
} from '@mantine/core';

import BackgroundImage from './background-image/Backgound-Image';

import img1 from '../hero/AdobeStock_185188995_Preview.jpeg';
import img2 from '../hero/AdobeStock_196226210_Preview.jpeg';
import img2_ from '../hero/AdobeStock_196226210_Preview-content-aware-fill.png';
import img3 from '../hero/AdobeStock_270898628_Preview.jpeg';
import img4 from '../hero/AdobeStock_463617997_Preview.jpeg';

// ==============================================

export default function F() {
  return (
    <div style={{ width: '100%', maxWidth: '1700px' }}>
      <Carousel
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={true}
        showThumbs={false}
        stopOnHover={true}
        swipeable={true}
        dynamicHeight={false}
        emulateTouch={true}
        // autoFocus={true}
        // onChange={onChange}
        // onClickItem={onClickItem}
        // onClickThumb={onClickThumb}
        thumbWidth={100}
        selectedItem={0}
        interval={3e3}
        transitionTime={500}
        swipeScrollTolerance={5}
      >
        <BackgroundImage
          img={img2_}
          quote="Dispensary Tulsa was a great experience from start to finish. Firstly, their website is very easy to use. I decided to do a Pickup Order. The process was smooth and quick. The actual store is super chill, all the staff were very helpful and kind. I'll definitely be coming back here very soon."
          author='Mary Gasky'
          full_stars={4}
          half_stars={1}
          empty_stars={0}
        />
        <BackgroundImage
          img={img1}
          quote='The staff is extremely friendly &amp; helpful. Love everyone there!
            Prices are great. You guys are amazing. 🙂 This place is seriously the best dispensary in Oklahoma!'
          author='Denny Moraine'
          full_stars={5}
          half_stars={0}
          empty_stars={0}
        />
        <BackgroundImage
          img={img3}
          quote="Great place! This place has a very wide variety of items! I was taken back by the amount of items you could buy ha. And one more thing pricing is EXTREMELY Affordable compared to the competitors. Trust me just stop in you'll be happy you did."
          author='Angel Guzman'
          full_stars={4}
          half_stars={1}
          empty_stars={0}
        />
        <BackgroundImage
          img={img4}
          quote='Super friendly and knowledge staff. Helped my first time at a dispensary go smoothly and made sure I was 100% satisfied and comfortable with my experience. 10/10 recommend.'
          author='Alfredo Cruz'
          full_stars={5}
          half_stars={0}
          empty_stars={0}
        />
        {/* <Image src='https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80&h=200' /> */}
        {/* <Image src='https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80&h=200' /> */}
        {/* <Image src='https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80&h=200' /> */}
      </Carousel>
    </div>
  );
}
