import sendShopifyStorefrontRequest from '../send-storefront-request';

import load_cart_query from '../queries/load-cart-query';

// ==============================================

export default async function loadCart(cart_id) {
  // --------------------------------------------

  // console.log('loadCart()');

  const data = await sendShopifyStorefrontRequest({
    query: load_cart_query,
    variables: { cart_id },
  });

  // console.log('loadCart() -- data: ', data);

  // --------------------------------------------

  return data;

  // --------------------------------------------
}

// ==============================================
