const request = async (query, variables = {}) => {
  // - - - - - - - - - - - - - - - - - - - - -

  // Default options are marked with *
  const res = await fetch(
    `https://dispensary-tulsa.myshopify.com/api/2022-01/graphql.json`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Shopify-Storefront-Access-Token': '88be3feb80a3ea0ef4b04fb51880d301',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    }
  );

  if (!res.ok) {
    // console.log('res.ok: ', res.ok);
    throw new Error(res);
  }

  // -errors for graphQl errors
  const { data, errors } = await res.json();

  if (errors) {
    console.log('GraphQL Error: errors: ', errors);
    throw new Error(errors[0].message ?? errors.message);
  }

  return data;
};

// ==============================================

export default request;
