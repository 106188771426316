import { useState, useRef } from 'react';
import {
  NumberInput,
  Group,
  ActionIcon,
  NumberInputHandlers,
} from '@mantine/core';

// ==============================================

export default function Demo({ quantity, setQuantity }) {
  // const [value, setValue] = useState(quantity);
  // const handlers = useRef();

  return (
    <Group spacing={5}>
      <ActionIcon
        size={42}
        variant='default'
        onClick={() => {
          // handlers.current.decrement();
          alert(
            "COMING SOON!\nFeature under development.\nPlease increase quantity by clicking 'Add to Cart' button multiple times."
          );
          // setQuantity((prev) => Math.max(0, prev - 1));
        }}
      >
        –
      </ActionIcon>

      <NumberInput
        hideControls
        value={quantity}
        onChange={(val) => setQuantity(val)}
        // handlersRef={handlers}
        max={10}
        min={0}
        step={2}
        styles={{ input: { width: 54, textAlign: 'center' } }}
      />

      <ActionIcon
        size={42}
        variant='default'
        onClick={() => {
          // handlers.current.increment();
          alert(
            "COMING SOON!\nFeature under development.\nPlease increase quantity by clicking 'Add to Cart' button multiple times."
          );
          // setQuantity((prev) => Math.min(100, prev + 1));
        }}
      >
        +
      </ActionIcon>
    </Group>
  );
}
