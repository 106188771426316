import { useState, useEffect } from 'react';
import { Chips, Chip } from '@mantine/core';

// ==============================================

export default function F({ variants }) {
  const [selected, setSelected] = useState(variants?.[0].title);
  let variant = variants?.[0];

  useEffect(() => {
    // console.log('selected: ', selected);

    variant = variants.find((variant) => variant.title === selected);
    // console.log('variant: ', variant);
  }, [selected]);

  return (
    <Chips radius='xs'>
      {variants.map((variant) => {
        return (
          <Chip key={variant.title} value={variant.title}>
            {variant.title}
          </Chip>
        );
      })}
    </Chips>
  );
}
