const normalizeCart = (data) => {
  const cart_sub_total = Number(data?.cart?.estimatedCost?.totalAmount?.amount);
  const cart_estimated_total = cart_sub_total; // TODO: Add tax!

  return {
    cart_sub_total,
    cart_estimated_total, // TODO: Add tax!
    lines: data?.cart?.lines?.edges.map((edge) => {
      const { node } = edge;
      const { merchandise, quantity, estimatedCost } = node;

      // -Line-Item Quantity:
      const line_item_quantity = quantity;

      // -Product Title:
      const product_title = merchandise?.product?.title;

      // -Variant Title:
      const { id, title: variant_title } = merchandise;

      // -Variant Image:
      const {
        // id: image_id,
        height: image_height,
        width: image_width,
        url: image_url,
        altText: image_alt,
      } = merchandise?.image;

      // -Variant Price:
      const { amount: variant_price } = merchandise?.priceV2;

      // -Line-Item Sub-Total:
      const line_item_sub_total =
        Number(variant_price) * Number(line_item_quantity);

      return {
        id,
        product_title,
        variant_title,
        variant_price,
        line_item_quantity,
        line_item_sub_total,
        image_height,
        image_width,
        image_url,
        image_alt,
      };
    }),
  };
};

// ==============================================

export default normalizeCart;
