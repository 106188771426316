export default async function sendShopifyStorefrontRequest({
  query,
  variables,
}) {
  // ==============================================
  try {
    // --------------------------------------------

    const res = await fetch(
      `https://dispensary-tulsa.myshopify.com/api/2022-01/graphql.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Accept: 'application/json',
          'X-Shopify-Storefront-Access-Token':
            '88be3feb80a3ea0ef4b04fb51880d301',
        },
        body: JSON.stringify({ query, variables }),
      }
    );

    // --------------------------------------------

    if (!res.ok) {
      throw new Error(res);
    }

    // --------------------------------------------

    const { data, errors } = await res.json();

    // --------------------------------------------

    if (errors) {
      throw new Error(errors[0].message ?? errors.message);
    }

    // --------------------------------------------

    if (!data) {
      throw new Error('No data returned from GraphQL.');
    }

    // --------------------------------------------

    return data;
  } catch (err) {
    console.log('error (create-cart.js): ', err);
  }
  // ==============================================
}
