import { useState, useEffect } from 'react';

// ==============================================

const useMounted = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return mounted;
};

// ==============================================

export { useMounted };
