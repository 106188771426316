import sendShopifyStorefrontRequest from '../send-storefront-request';

import add_to_cart_mutation from '../queries/add-to-cart-mutation';

// ==============================================

export default async function addToCart({ cart_id, variant_id }) {
  // --------------------------------------------

  // console.log('addToCart()');

  const data = await sendShopifyStorefrontRequest({
    query: add_to_cart_mutation,
    variables: { cart_id, variant_id },
  });

  // console.log('addToCart() -- data: ', data);

  // --------------------------------------------

  return data;

  // --------------------------------------------
}

// ==============================================
