import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { MediaQuery, Text, Center } from '@mantine/core';

import Hamburger from './Hamburger';
// import Logo from './Logo';
import Logo from './Logo-SVG';
import Cart from '../svg/Cart';
import { BagFull, BagEmpty } from '../svg/Bag';

import css from './Header.module.scss';

// ==============================================

const div_variants = {
  hidden: { y: -250 },
  visible: { y: 0, transition: { delay: 0.2, type: 'spring', stiffness: 120 } },
};

// ==============================================

const svgVariants = {
  // hidden: { rotate: -180 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 2,
      ease: 'easeInOut',
    },
  },
};

// ==============================================

const F = forwardRef((p, ref) => {
  // --------------------------------------------

  return (
    <header
      id='header'
      ref={ref}
      style={{
        position: 'fixed',
        width: '100%',
        height: '100px',
        background: 'rgba(20,22,24,0)',
      }}
    >
      <div className={css.container}>
        <motion.div
          className={css.svg_container}
          onClick={() => p.setDrawerOpen((prev) => !prev)}
          variants={div_variants}
          initial='hidden'
          animate='visible'
        >
          <Hamburger />
        </motion.div>

        <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
          <Text size='md'>Dispensary Tulsa</Text>
        </MediaQuery>

        <motion.div
          className={css.svg_container}
          onClick={() => p.setCartDrawerOpen((prev) => !prev)}
          variants={div_variants}
          initial='hidden'
          animate='visible'
        >
          <BagEmpty />
        </motion.div>
      </div>
    </header>
  );

  // --------------------------------------------
});

// ==============================================

export default F;
